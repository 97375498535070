<template>
  <div class="article-grid">
    <h4 class="px-2 text-uppercase mb-1 mt-3">
      {{ $t("recaledProducts.title") }}
    </h4>
    <category-block
      :target="category"
      position="position1"
      class="category-block category-block-1"
    />
    <v-container>
      <v-row
        ><v-col
          cols="12"
          sm="6"
          md="6"
          lg="4"
          v-for="article in articleList"
          :key="article.id"
        >
          <RecalledProduct :product="article" /> </v-col
      ></v-row>
      <div
        v-if="articleList.length < 1 && !initLoading"
        class="text-center font-weight-semibold secondary--text text-body-0 py-6"
      >
        {{ noDataMessage }}
      </div>
      <v-btn
        v-if="hasMoreItems()"
        block
        text
        large
        depressed
        rounded
        color="primary"
        @click="loadMore()"
        v-intersect="onIntersect"
        class="mt-3"
        :loading="loading"
        >{{ $t("recipes.showMore") }}</v-btn
      >
    </v-container>
    <category-block
      :target="category"
      position="position2"
      class="category-block category-block-2"
    />
  </div>
</template>
<style lang="scss">
.article-grid {
  .v-chip:not(.v-chip--active) {
    background-color: var(--v-grey-lighten1) !important;
    border-color: var(--v-grey-lighten1) !important;
  }
  .v-chip-group .v-chip:first-child {
    margin-left: 8px;
  }
  .active-chip {
    background-color: $primary !important;
    border-color: $primary !important;
    color: white;
  }
}
</style>
<script>
import RecalledProduct from "@/components/article/RecalledProduct.vue";

import cmService from "~/service/mksCmService";
import categoryMixins from "~/mixins/category";
import get from "lodash/get";

export default {
  components: { RecalledProduct },
  name: "ProductRecallGrid",
  mixins: [categoryMixins],
  data() {
    return {
      articleList: [],
      articleClasses: [],
      selectedClass: null,
      pager: null,
      page: 0,
      count: 0,
      mergedFilterData: [],
      pageSize: 24,
      loading: false,
      initLoading: false
    };
  },
  computed: {
    noDataMessage() {
      return get(
        this.category,
        "metaData.category_info.NO_DATA_TEXT",
        this.$t("message.noData")
      );
    }
  },
  methods: {
    async reload() {
      // let parentArticleTypeId = this.$ebsn.meta(
      //   this.category,
      //   "template_model.ARTICLE_TYPE"
      // );
      let params = {
        parent_article_type_id: 3,
        page_size: this.pageSize
      };

      if (this.$route.query["article_class_id"]) {
        params["article_class_id"] = this.$route.query["article_class_id"];
      }
      if (this.$route.query.page) {
        params["page"] = this.$route.query.page;
      }
      if (params["article_class_id"]) {
        this.selectedClass = parseInt(params["article_class_id"]);
      } else {
        this.selectedClass = null;
      }
      this.initLoading = true;
      let content = await cmService.searchArticle(params);
      this.initLoading = false;
      this.articleList = content?.data?.articles;
      this.pager = content?.data?.page;
      this.articleClasses = content?.data?.facets;
      this.key++;
    },
    handlePageChange(page) {
      // let queryParam = Object.assign(this.$route.query, { page: page });
      // this.$router.push({
      //   path: this.$route.path,
      //   query: queryParam
      // });
      const query = { ...this.$route.query, page: page };
      this.$router.push({ query });
    },
    async loadMore() {
      if (this.hasMoreItems()) {
        this.loading = true;
        let params = {
          page: this.pager.selPage + 1,
          page_size: this.pageSize
        };
        let content = await cmService.searchArticle(params);
        this.loading = false;
        this.pager = content?.data?.page;
        this.articleList.push(...content?.data?.articles);
      }
    },
    hasMoreItems() {
      if (this.pager) {
        return this.pager.totPages > this.pager.selPage;
      } else {
        return false;
      }
    },
    onIntersect(entries) {
      if (entries[0].isIntersecting) {
        this.loadMore();
      }
    }
  },
  async created() {
    await this.reload();
  },
  watch: {
    "$route.query.page": function() {
      this.reload();
    }
  }
};
</script>
