<template>
  <v-card
    class="recalled-product-card align-center"
    :class="size"
    outlined
    :to="{ name: product.articleType.name, params: { pageName: product.slug } }"
  >
    <div class="d-flex align-center">
      <div class="action-icons">
        <v-icon>$richiamoProdotti</v-icon>
      </div>
      <div class="product-img-wrapper">
        <v-img
          :src="refImageUrl"
          :alt="refImageAlt"
          :height="imgHeight"
          :max-height="imgHeight"
          onerror="this.src='/no-icon.png'"
          class="product-img"
          contain
        ></v-img>
      </div>
      <div class="flex-grow-1 align-self-start py-1">
        <v-card-subtitle
          class="w-100 py-1"
          v-if="product.metaData.articletype.product_date"
        >
          {{
            $dayjs(product.metaData.articletype.product_date).format(
              "DD/MM/YYYY"
            )
          }}
        </v-card-subtitle>
        <v-card-title class="w-100 py-0">{{ product.title }}</v-card-title>
        <v-card-subtitle class="w-100 mt-1 pt-0">
          {{ product.metaData.articletype?.short_desc }}
        </v-card-subtitle>
      </div>
      <div>
        <v-icon class="default--text mr-3 ml-1">$chevronRight</v-icon>
      </div>
    </div>
  </v-card>
</template>
<style lang="scss">
.recalled-product-card {
  border-color: #e65954 !important;
  border-radius: 8px !important;
  overflow: hidden;
  .action-icons {
    position: absolute;
    z-index: 2;
    left: 8px;
    top: 5px;
    .v-icon {
      color: #e65954 !important;
    }
  }
  .product-img-wrapper {
    width: 30%;
    display: flex;
  }
  .v-card__subtitle,
  .v-card__text,
  .v-card__title {
    padding: 8px;
  }
  .v-card__title {
    font-size: 16px;
    line-height: 18px;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    line-clamp: 4;
    -webkit-box-orient: vertical;
    align-items: flex-start;
  }
  .v-card__subtitle {
    font-size: 14px;
    line-height: 16px;
    font-weight: normal;
  }
}
</style>
<script>
import has from "lodash/has";

export default {
  name: "RecalledProduct",
  props: {
    product: {
      type: Object,
      required: true
    },
    size: {
      type: String,
      default: "normal"
    },
    confirmRemove: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    refImageUrl() {
      return has(this.product, "media[0].url")
        ? this.product.media[0].url
        : this.product.refImageUrl
        ? this.product.refImageUrl
        : "/no-icon.png";
    },
    refImageAlt() {
      return this.product.title || this.product.title;
    },
    imgHeight() {
      if (this.size == "small") {
        return 100;
      } else {
        return 100;
      }
    },
    categories() {
      return this.product?.articleClass?.filter(
        ac => ac.articleClassGroup.name == "category"
      );
    }
  },
  methods: {}
};
</script>
